import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {
	Button,
	Grid,
	Snackbar,
	Typography
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import {FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {CONTENT_FORM} from "api/formConfig";
import useTheme from "api/siteTheme";
import useRequests from "api/useRequests";
import useUpload from "api/uploader";
import useContentType from "api/useContentType";
import InputField, {
	inputFileValidator,
	inputSelectValidator,
	inputTextValidator,
	inputDatePickerValidator,
	inputMultiFieldValidator
} from "components/InputField";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ContentsForm({content, id}) {
	const theme = useTheme();
	const contentType = useContentType();
	const location = useLocation();
	const upload = useUpload();
	const requestToSend = useRequests();
	
	const [initialValues, setInitialValues] = useState({});
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [responseText, setResponseText] = useState('');
	const [responseType, setResponseType] = useState('');
	const [responseRequestPost, setResponseRequestPost] = useState();
	const [responseRequestPut, setResponseRequestPut] = useState();
	const [questionType, setQuestionType] = useState();
	
	const formRef = useRef();
	const formPage = formRef.current;
	
	const contentForm = CONTENT_FORM[contentType];
	const isCreating = location.pathname.includes('create');
	
	const urlPut = contentType === 'product_manager'
		? `/api/v1/${contentType}?id=${id}`
		: `/api/v1/content_manager/${contentType}?id=${id}`;
	
	const urlPost = contentType === 'product_manager'
		? `/api/v1/${contentType}`
		: `/api/v1/content_manager/${contentType}`;
	
	const contentFields = useMemo(() => (
		contentForm.fields?.flatMap((field) => (
			field.fieldType === "mask"
				? flattenMaskField(field)
				: [field]
		))
	), [contentForm]);
	
	const formik = useFormik({
		initialValues: getInitialValues(),
		validationSchema: getValidationSchema(),
		onSubmit: async (values, actions) => {
			await handleFormSubmit(contentFields, values);
			actions.setSubmitting(false)
		},
	});
	
	useEffect(() => {
		if (!content) return;

		if (contentFields.length > 0) {
			contentFields?.forEach((field) => {
				const valuesObjectMultiField =
					Array.isArray(content[0][field.fieldName])
						? content[0][field.fieldName].map((item, index) => {
						    if(field.fieldName !== 'answers'){
                                return {
                                    id: index,
                                    ...item
                                }
							}
							return {
							    id: index,
							    text_content: item
							};
						})
						: null;


				const fieldValue =
					(field.fieldType === "select" && field.maxValues !== 1)
						? Object.values(content[0][field.fieldName])
						: field.fieldType === 'multiField'
							? valuesObjectMultiField
							: field.fieldType === "boolean"
								? content[0][field.fieldName]
								: content[0][field.fieldName]
									? content[0][field.fieldName]
									: '';
				
				formik.setFieldValue(
					field.fieldName, //field name
					fieldValue, //field value
					false, //validate
				);
			});
		}
	}, [content]);// formik is reassigned at each field change
	
	useEffect(() => {
		if (isCreating && contentType === "product_manager") {
			formik.setFieldValue(
				'type', //field name
				'learning', //field value
				false, //validate
			);
		}
	}, [contentType])

	useEffect(() => {
		const questionTypeValue = formik.values.question_type;
		setQuestionType(questionTypeValue)
	}, [formik])
	
	useEffect(() => {
		const currentForm = document.querySelector('form');
		
		var evt = new MouseEvent('contextmenu', {
			bubbles: true,
			cancelable: true,
			view: window,
			buttons: 2
		});
		currentForm.dispatchEvent(evt);
		
	}, [formPage]);
	
	useEffect(() => {
		if (responseRequestPut) {
			setResponseType(responseRequestPut?.[0]?.status);
			setResponseText(responseRequestPut?.[0]?.data?.[Object?.keys(responseRequestPut?.[0]?.data)?.toString()] || responseRequestPut?.[0]?.message);
			setTimeout(() => {
				navigate(-1)
			}, 800)
		} else if (responseRequestPost) {
			setResponseType(responseRequestPost?.[0]?.status || responseRequestPost?.[0]?.toString());
			setResponseText(responseRequestPost?.[0]?.message?.toString() || responseRequestPost?.[0]?.toString());
			setTimeout(() => {
				navigate(-1)
			}, 800)
		}
	}, [navigate, responseRequestPost, responseRequestPut])
	
	return (
		<Grid
			container
			justifyContent="space-between"
			flexWrap={'nowrap'}
			height={'100%'}
		>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				autoHideDuration={8000}
				onClose={handleClose}
				open={open}
			>
				<Alert
					onClose={handleClose}
					severity={`${responseType}` || ''}
					sx={{width: '100%'}}
				>
					{responseText || ''}
				</Alert>
			</Snackbar>
			<Grid
				item
				xs={12}
				height={'100%'}
				justifyContent="center"
			>
				<Grid
					item xs={12}
					align="center"
					color={`${theme.palette.primary.main}`}
					component={Typography}
					gutterBottom
					padding={'20px 0'}
					textTransform="capitalize"
					variant="h3"
				>
					{contentForm.labelContentType}
				</Grid>
				<FormikProvider value={formik}>
					<Grid
						component="form"
						item xs={12}
						sx={{height: 'calc(100% - 180px)'}}
						onContextMenu={() => {
							if (formPage) {
								setInitialValues(formik.values)
							}
						}}
						onSubmit={(e) => {
							e.preventDefault()
							formik.handleSubmit();
						}}
						ref={formRef}
					>
						{
							(contentForm.fields.find(field => field.fieldType === 'date') && !Boolean(isCreating)) ? (
								<Grid
									item
									sm={12}
									display={'flex'}
									marginBottom={'10px'}
									max-width={'40%'}
									padding={'16px 32px'}
									top={0}
								>
									{contentForm.fields.map((field, index) => {
										const currentValue = new Date(formik.values[field.fieldName]).toLocaleDateString();
										return (
											field.fieldType === 'date' ? (
												<Grid
													variant={'h3'}
													key={index.toString()}
													marginRight={'16px'}
												>
													{field.fieldLabel.toUpperCase() + ': ' + currentValue}
												</Grid>
											) : null)
									})}
								</Grid>
							) : null
						}
						<Grid
							display='flex'
							flexWrap='nowrap'
							height='100%'
							justifyContent='space-between'
							paddingX='16px'
						>
							<Grid
								item
								xs={12}
								container
								display={'block'}
								paddingRight={'16px'}
								rowSpacing={4}
							>
								{contentForm.fields.map((field, index) => {
									return (
										field.fieldType !== 'dateInfo' && field.fieldType !== 'date'
									
									) ? (
										<Grid key={`field-${index}`} item xs={12} height={'fit-content'}>
											<InputField
												contentFields={contentFields}
												contentType={contentType}
												field={field}
												form={formik}
												isCreating={isCreating}
												multiple={field.multiple}
												id={id}
											/>
										</Grid>
									) : null
								})}
							</Grid>
							{
								contentForm.fields.some(field => field.fieldType === 'dateInfo') ? (
									<Grid
										boxShadow=' rgba(0, 0, 0, 0.15) 0px 0px 15px'
										borderRadius='20px'
										height='fit-content'
										paddingTop='16px'
										paddingX='16px'
										position='sticky'
										top={16}
										width='40%'
									>
										{contentForm.fields.map((field, index) => (
											field.fieldType === 'dateInfo' ? (
												<Grid key={`field-${index}`} item xs={12}>
													<InputField
														contentFields={contentFields}
														field={field}
														form={formik}
													/>
												</Grid>
											) : null
										))}
									</Grid>
								) : null
							}
						</Grid>
						<Grid
							item
							xs={12}
							container
							bottom={0}
							left={0}
							right={0}
							fullwidth="true"
							justifyContent="center"
							position='sticky'
							style={{
								background: 'white',
								boxShadow: '0 0 15px rgba(0, 0, 0, 0.15)',
								zIndex: '3',
							}}
						>
							<Grid
								item
								padding='16px'
								width='fit-content'
							>
								<Button
									fullwidth="true"
									variant="outlined"
									onClick={() => navigate(-1)}
								>
									annulla
								</Button>
							</Grid>
							<Grid
								item
								padding={'16px'}
								width={'fit-content'}
							>
								<Button
									fullwidth="true"
									type="submit"
									variant="contained"
									sx={{color: 'white'}}
								>
									salva
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</FormikProvider>
			</Grid>
		</Grid>
	);
	
	function flattenMaskField(field) {
		return [
			{...field, fieldType: "boolean"},
			...field.subFields,
		]
	};
	
	function handleClose(event, reason) {
		if (reason === 'clickaway') return;
		setOpen(false);
	};
	
	function getInitialValues() {
		return contentFields.reduce((acc, field) => {
			acc[field.fieldName] = field.fieldValue;
			return acc;
		}, {});
	};
	
	function getValidationSchema() {
		return yup.object(
			contentFields.reduce((acc, field) => ({
				...acc, ...getFieldValidation(field),
			}), {})
		)
	};
	
	function getFieldValidation(field) {
		switch (field.fieldType) {
			case "file" :
				return ({
					...inputFileValidator({
						controlLabel: field.fieldLabel,
						controlName: field.fieldName,
						controlIsRequired: field.required,
					})
				});
			case  "image":
				return ({
					...inputFileValidator({
						controlLabel: field.fieldLabel,
						controlName: field.fieldName,
						controlIsRequired: field.required,
					})
				});
			case "select":
				return ({
					...inputSelectValidator({
						controlLabel: field.fieldLabel,
						controlName: field.fieldName,
						controlMaxValues: field.maxValues,
						controlMinValues: field.minValues,
					})
				});
			case "text":
				return ({
					...inputTextValidator({
						controlLabel: field.fieldLabel,
						controlName: field.fieldName,
						controlIsRequired: field.required || (questionType === 'question_evaluation' && (
							field.fieldName === 'comment_placeholder' ||
							field.fieldName === 'max_value' || field.fieldName === 'max_value_label' ||
							field.fieldName === 'min_value' || field.fieldName === 'min_value_label'
						)),
					})
				});
			case "dateInfo":
				return ({
					...inputDatePickerValidator(
						{
							controlLabel: field.fieldLabel,
							controlName: field.fieldName,
							controlIsRequired: field.required,
						}
					)
				})
			case "multiField" :
				return (
					{
						...inputMultiFieldValidator({
								controlLabel: field.fieldLabel,
								controlName: field.fieldName,
								controlIsRequired: field.required || ((questionType === 'question_single' || questionType === 'question_multiple') && (
									field.fieldName === 'answers'
								)),
							}
						)
					}
				)
			default: {
			}
		}
		;
	};
	
	
	
	async function handleFormSubmit(contentFields, values) {
		
		let newValues = {...values};
		
		const getChangedValuesPost = () => {
			return Object
				?.entries(newValues)
				?.reduce((acc, [key, value]) => {
					const hasChanged = Array.isArray(value) ? JSON.stringify(initialValues[key]) !== JSON.stringify(value) : initialValues[key] !== value
					const isRequired = contentFields.find(content => content.fieldName === key)?.required;
					const PackageTypeCreation = isCreating && key === "type";
					
					if (hasChanged || isRequired || PackageTypeCreation) {
						acc[key] = value;
					}
					return acc
				}, {})
		}
		
		const getChangedValuesPut = () => {
			return Object
				?.entries(newValues)
				?.reduce((acc, [key, value]) => {
					const hasChanged = Array.isArray(value) ? JSON.stringify(initialValues[key]) !== JSON.stringify(value) : initialValues[key] !== value
					if (hasChanged) {
						acc[key] = value;
					}
					return acc
				}, {})
		}
		
		let changedValuesPost = getChangedValuesPost();
		let changedValuesPut = getChangedValuesPut()
		
		contentFields.forEach(field => {
			if (field.fieldType === 'select' || field.fieldType === 'select_file'  ) {
				changedValuesPost = {
					...changedValuesPost, [field.fieldName]: changedValuesPost[field.fieldName]?.map(item => {
						return item?.id
					}).toString()
				}
				changedValuesPut = {
					...changedValuesPut, [field.fieldName]: changedValuesPut[field.fieldName]?.map(item => {
						return item?.id
					}).toString()
				}
			} else if (field.fieldType === 'select_media') {
				if (changedValuesPost[field.fieldName]) {
					changedValuesPost = {
						...changedValuesPost, [field.fieldName]: changedValuesPost[field.fieldName]?.id
					}
				}
				if (changedValuesPut[field.fieldName]) {
					changedValuesPut = {
						...changedValuesPut, [field.fieldName]: changedValuesPut[field.fieldName]?.id
					}
				}
			} else if (field.fieldType === 'multiField' && field.fieldName === 'answers' && (
				(changedValuesPost[field.fieldName]?.length > 0 && Array.isArray(changedValuesPost[field.fieldName]))
				|| (changedValuesPut[field.fieldName]?.length > 0 && Array.isArray(changedValuesPut[field.fieldName])))
			) {
				const answersPost = changedValuesPost[field.fieldName].map(item => {
					return item.text_content
				});
				const answersPut = changedValuesPut[field.fieldName].map(item => {
					return item.text_content
				});
				changedValuesPost = {...changedValuesPost, answers: answersPost};
				changedValuesPut = {...changedValuesPut, answers: answersPut};
			}
		})
		if (changedValuesPut || changedValuesPost) {
			UpDateContent(changedValuesPost, changedValuesPut);
		}
		// console.log("form payload", JSON.stringify(isCreating ? changedValuesPost : changedValuesPut, null, 4));
	};
	
	async function UpDateContent(valuesToSendPost, valuesToSendPut) {
		let responseRequest;
		if (isCreating) {
			const request = requestToSend([valuesToSendPost], urlPost, 'POST');
			responseRequest = await Promise.all(request)?.then(data => {
					setOpen(true);
					return data
				}
			)?.catch(error => {
				setOpen(true);
				return error
			});
			setResponseRequestPost(responseRequest)
		} else {
			const request = requestToSend([valuesToSendPut], urlPut, 'PUT');
			responseRequest = await Promise.all(request)?.then(data => {
					setOpen(true);
					return data
				}
			)?.catch(error => {
				setOpen(true);
				return error
			});
			setResponseRequestPut(responseRequest)
		}
	}
};


export default ContentsForm;
