import useEnvironment from "api/useEnvironment";

function useUpload() {
  const { environment } = useEnvironment();

	
	async function upload({field, file, endpoint, name, ask_the_expert, method = "POST"}) {
		
		let domainUrl = "https://prelios.sit.digitedacademy.net"; // TO DO CHANGE WITH window.location.origin;
		const body = field === 'cover' ? {
			body: JSON.stringify({
				[field]: file,
				name: name,
			}),
			endpoint: endpoint,
			domainUrl: domainUrl,
			method: method
		} : {
			body: JSON.stringify({
				[field]: file,
				name: name,
			}),
			endpoint: endpoint,
			ask_the_expert: ask_the_expert,
			domainUrl: domainUrl,
			method: method
		}

    let baseUrl;
    switch (environment) {
        case "dev":
                baseUrl = "https://s4b2apidev.ispfdevelop.com";
                break;
        case "prod":
                baseUrl = "https://api.skills4business.it";
                break;
        case "sit":
                baseUrl = "https://s4b2api.ispfdevelop.com";
                break;
        case "mock":
                baseUrl = '';
                break;
        default:
            throw new Error(`unknown environment ${environment}`);
    };
		
		const res = await fetch(
			baseUrl + "/proxy/api/v1/public_proxy",
			{
				body:
					JSON.stringify(body),
				headers: {
					'content-type': 'application/json',
					"x-api-key": "g1KSFg7H1A9QzGhSASviP8EmXqAsqqGt9iERQo2p",
				},
				method: method,
				reportProgress: true,
				responseType: 'json',
				
			}
		)
		if (!res.ok){
			let json = await res.json();
			json = {...json, status: 'error'}
			return json
		}
		else {
			let json = await res.json();
			 json = json.message.match(/\d/) ? json.message.replace(/\D/g, '') : json
			
			return json
		}
		
		
	};
	
	const readFile = ({field, file, endpoint, name, ask_the_expert, method}) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
				
				upload({
					field,
					file: base64String,
					endpoint,
					name: file.name,
					ask_the_expert,
					method,
				})
					?.then(res => res)
					?.then(data => {
						resolve({field, data, file: file.name})
					})
					?.catch(error => {
						reject(error)
					});
				
			});
			reader.addEventListener("error", (error) => {
				reject(error);
			})
			reader.readAsDataURL(file);
		})
	};
	
	const readPayload = (payload) => {

		return Array.isArray(payload)
			? payload.map(payload => readFile(payload))
			: [payload].map(payload => readFile(payload))
	};
	
	return readPayload;
}

export default useUpload;
