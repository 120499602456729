import React, { useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Grid, Typography} from "@mui/material";
import {FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import useRequests from "api/useRequests";
import useTheme from "api/siteTheme";
import {POST_FIELDS} from "api/postFields";
import InputField, {
	inputFileValidator,
	inputTextValidator,
} from "components/InputField";
import Icon from "components/Icon";
import Spinner from "components/Spinner";
import useUploadMiddleware from "@/api/uploader_mid";

export default function CreatePost() {
  	const userId = localStorage.getItem("UserId");
	const [open, setOpen] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	const [responseText, setResponseText] = useState('');
	const [responseType, setResponseType] = useState('');
	const [responseRequestPost, setResponseRequestPost] = useState();
	const [valuesToSend, setValuesToSend] = useState();
	const [loading, setLoading] = useState(false);
	const upload = useUploadMiddleware();
	
	const urlPost = `/newsfeed/api/v1/newsfeed/share`;
	const theme = useTheme();
	const contentForm = POST_FIELDS;
	
	const navigate = useNavigate();
	
	
	const contentFields = contentForm.fields?.flatMap((field) => {
		return field.fieldName === 'userId' ? {
				...field,
				// fieldValue: localStorage.getItem("UserId") || '',
				fieldValue: userId || '',
			} :
			[field]
	});
	
	const formik = useFormik({
		initialValues: getInitialValues(),
		validationSchema: getValidationSchema(),
		onSubmit: async (values, actions) => {
			await handleFormSubmit(contentFields, values);
			actions.setSubmitting(false)
		},
	});
	
	
	useEffect(() => {
		if (responseRequestPost) {
			if (Boolean(valuesToSend.imageUrl && Boolean(responseRequestPost?.[0]?.success))) {
				const newsFeedId = responseRequestPost[0]?.newsfeeds[0]?.newsfeedId;

				handleFileUpload(contentFields, valuesToSend, newsFeedId)
			} else {
				setLoading(false);
				setResponseType(responseRequestPost[0]?.status);
				setResponseText(Boolean(responseRequestPost?.[0]?.success)
					? 'Post creato correttamente'
					: responseRequestPost?.[0]?.message
						|| responseRequestPost?.[0]?.errorMessage
						||  responseRequestPost?.[0]?.toString()
						|| 'Errore'
				);
			}
		}
	}, [responseRequestPost])

	const requestToSend = useRequests();
	
	async function handleFormSubmit(contentFields, values) {
		const newValues = {...values};
		setValuesToSend(values);

		setDisableButton(true)
		if (newValues) {
			UpDateContent(newValues);
		}
	};

	async function UpDateContent(valuesToSendPost) {
		setLoading(true);

		valuesToSendPost['imageUrl'] = null;

		let responseRequest;
		const request = requestToSend([valuesToSendPost], urlPost, 'POST', true, localStorage.getItem('Authorization'));
		responseRequest = await Promise.all(request)?.then(data => {
				setOpen(true);
				document.body.classList.add('has-mask')
				return data
			}
		)?.catch(error => {
			setOpen(true);
			document.body.classList.add('has-mask')
			return error
		});
		setResponseRequestPost(responseRequest);
	}

	
	document.body.addEventListener('click', (e) => {
		if (open && e.target.classList.contains('has-mask')) {
			document.body.classList.remove('has-mask')
			setOpen(false)
			window.location.reload()
			
		}
	})

	async function handleFileUpload(contentFields, values, newsFeedId) {
		let payload = [];
		Object.values(contentFields)
			.filter((field) => (
				(field.fieldType === "file")
				&& Boolean(values[field.fieldName]?.name)
			))
			.map((element) => (
				payload.push(
					{
						file: values[element.fieldName],
						endpoint: `/social/api/v1/upload/newsfeed?user_id=${userId}&newsfeed_id=${newsFeedId}`,
						userId: userId,
						newsFeedId: newsFeedId,
					}
				)
			));

		if (!payload || payload.length === 0) {
			return values;
		} else {
			const uploadResponse = upload(payload);
			const promiseResponse = await Promise.all(uploadResponse).then(fileValues =>  fileValues).catch(error => {
				console.log(error)
			})
			
			if (promiseResponse[0]?.data?.status === '200') {
				setLoading(false);
				setOpen(true);
				setResponseType('success');
				promiseResponse?.forEach(res => {
					values[res.field] = res.data.toString();
				})
				return values
			} else {
				setLoading(false);
				setOpen(true);
				setResponseType('error')
				setResponseText(promiseResponse[0]?.data?.message)
				return values
			}
		}
	}

	const PopUp = () => {
		return (
			
			<Grid
				container
				height={'100%'}
				alignItems={'center'}
				className={open ? 'visible' : ''}
				sx={{
					transform: 'scale(0)',
					maxWidth: '600px',
					maxHeight: '300px',
					pointerEvents: 'none',
					position: 'fixed',
					top: '0',
					right: '0',
					bottom: '0',
					left: '0',
					margin: 'auto',
					borderRadius: '20px',
					boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 15px',
					transition: 'transform .5s linear .2s',
					background: 'white',
					padding: '16px',
					'&.visible': {
						transform: 'scale(1)',
						pointerEvents: 'auto',
						zIndex: '5',
					},
					[theme.breakpoints.down('sm')]: {
						margin: 'auto 16px',
						width:'auto'
					}
				}}
			>
				
				<Grid
					item
					xs={12}
					container
					display={'flex'}
					flexDirection={'column'}
					justifyContent={'space-between'}
					height={'200px'}
				>
					<Grid display={'flex'}
					      flexDirection={'column'}
					      justifyContent={'space-between'} alignItems={'center'}>
						<Typography
							sx={{
								color: responseType === 'success' ? '#4caf50' : '#f44336',
								textAlign: 'center',
								marginBottom: '16px'
							}}>{responseType === 'error' ? `si è verificato il seguente errore: ${responseText}` : `${responseText}, post condiviso con successo`}</Typography>
						
						<Icon type={responseType === 'success' ? 'SmileIcon' : 'SadIcon'}
						      sx={{color: responseType === 'success' ? '#4caf50' : '#f44336'}} fontSize={'large'}/>
					</Grid>
					
					
					<Grid display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
						
						<Button
							fullwidth="true"
							type="submit"
							variant="contained"
							sx={{color: 'white', margin: '8px auto 0', display: 'flex', height: 'fit-content'}}
							onClick={() => {
								navigate('/');
								document.body.classList.remove('has-mask')
							}}
						>
							HomePage
						</Button>
						
						<Button
							fullwidth="true"
							type="submit"
							variant="contained"
							sx={{color: 'white', margin: '8px auto 0', display: 'flex', height: 'fit-content'}}
							onClick={() => window.location.reload()}
						>
							Crea Nuovo Post
						</Button>
					</Grid>
				
				
				</Grid>
			</Grid>
		)
	}
	
	
	return (
		<Grid
			container
			justifyContent="space-between"
			flexWrap={'nowrap'}
			height={'100%'}
		> 
			{!loading && <PopUp/>}
			<Grid
				item
				xs={12}
				justifyContent="center"
				alignItems="flex-start"
				height={'100%'}
			>
				<Grid
					item xs={12}
					align="left"
					component={Typography}
					variant="h3"
					gutterBottom
					textTransform="capitalize"
					padding={'20px'}
					color={theme.palette.primary.main}
				>
					{contentForm.contentType}
				</Grid>
				<FormikProvider value={formik}>
					
					<Grid
						component="form"
						item xs={12}
						sx={{height: 'calc(100% - 180px)'}}
						onSubmit={(e) => {
							formik.handleSubmit();
							e.preventDefault()
						}}
					>
						<Grid justifyContent={'space-between'} display={'flex'} flexWrap={'nowrap'} height={'100%'}>
							<Grid
								item
								xs={12}
								container
								rowSpacing={4}
								display={'block'}
								padding={'16px 32px'}>
								{contentForm.fields.map((field, index) => {
									return (
										(field.fieldName === 'description' || field.fieldName === 'title' || field.fieldName === 'imageUrl') ? (
											<Grid key={`field-${index}`} item xs={12} height={'fit-content'}>
												<InputField
													contentFields={contentFields}
													field={field}
													form={formik}
												/>
											</Grid>
										) : null
									)
								})}
								{loading && <div className="spinner-wrapper" 
									style={{
										height: "auto",
										paddingTop: '30px'
									}}>
									<Spinner/>
								</div>}
							</Grid>
						</Grid>
						
						<Grid
							item
							xs={12}
							container
							fullwidth="true"
							justifyContent="center"
							position={'sticky'}
							bottom={0}
							right={0}
							left={0}
							style={{background: 'white', zIndex: '3', boxShadow: '0 0 15px rgba(0, 0, 0, 0.15)'}}
						>
							<Grid item padding={'16px'} width={'fit-content'}>
								<Button
									fullwidth="true"
									variant="outlined"
									onClick={() => {
										navigate('/');
										document.body.classList.remove('has-mask')
									}}
								
								>
									annulla
								</Button>
							</Grid>
							<Grid item width={'fit-content'} padding={'16px'}>
								<Button
									fullwidth="true"
									type="submit"
									variant="contained"
									sx={{color: 'white'}}
									disabled={disableButton}
								>
									salva
								</Button>
							</Grid>
						</Grid>
					</Grid>
				
				</FormikProvider>
			</Grid>
		
		</Grid>
	);
	
	function getInitialValues() {
		return contentFields.reduce((acc, field) => (
			{
				...acc,
				[field.fieldName]: field.fieldValue,
			}), {});
	};
	
	function getValidationSchema() {
		return yup.object(
			contentFields.reduce((acc, field) => ({
				...acc, ...getFieldValidation(field),
			}), {})
		)
	};
	
	function getFieldValidation(field) {
		
		switch (field.fieldType) {
			case  "image":
				return ({
					...inputFileValidator({
						controlLabel: field.fieldLabel,
						controlName: field.fieldName,
						controlIsRequired: field.required,
					})
				});
			case "text":
				return ({
					...inputTextValidator({
						controlLabel: field.fieldLabel,
						controlName: field.fieldName,
						controlIsRequired: field.required,
					})
				});
			case "html":
				return ({
					...inputTextValidator({
						controlLabel: field.fieldLabel,
						controlName: field.fieldName,
						controlIsRequired: field.required,
					})
				});
			default: {
			
			}
		}
		;
	};
};


