export const CONTENT_FORM = {
	selection: {
		contentType: "selection",
		labelContentType: "Selection",
		fields: [
			{
				fieldName: "title",
				fieldLabel: "titolo",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "body",
				fieldLabel: "descrizione",
				fieldType: "html",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "cover",
				fieldLabel: "immagine",
				endpoint: "/image",
				fieldType: "select_media",
				fieldValue: [],
				maxValues: 1,
				minValues: 0,
				property: "title",
				required: false,
			},
			{
				fieldName: "count_content",
				fieldLabel: "numero cotenuti",
				fieldType: "",
				fieldValue: '',
				required: false,
			},
			{
				fieldName: "creation_date",
				fieldLabel: "data creazione",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "updated_date",
				fieldLabel: "ultimo aggiornamento",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "survey",
				fieldLabel: "survey",
				endpoint: "/survey",
				fieldType: "select",
				fieldValue: "",
				maxValues: 1,
				minValues: 0,
				property: "name",
				required: false,
			},
			{
				fieldName: "roles",
				fieldLabel: "Ruoli associati",
				endpoint: "/role",
				fieldType: "select",
				fieldValue: [],
				maxValues: -1,
				minValues: 0,
				property: "name",
				required: false,
			},
			{
				fieldName: "levels",
				fieldLabel: "Livelli associati",
				endpoint: "/level",
				fieldType: "select",
				fieldValue: [],
				maxValues: -1,
				minValues: 0,
				property: "name",
				required: false,
			},
			{
				fieldName: "new_hired",
				fieldLabel: "New Hired",
				fieldType: "boolean",
				fieldValue: "false",
				required: false,
			},
			{
				fieldName: "esigenze_formative",
				fieldLabel: "esigenze formative",
				endpoint: "/esigenze_formative",
				fieldType: "select",
				fieldValue: [],
				maxValues: 1,
				minValues: 1,
				property: "name",
				required: true,
			},
			{
				fieldName: "product_auto_add",
				fieldLabel: "assegnazione automatica",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			},
			{
				fieldName: "content",
				fieldLabel: "Contenuti obbligatori associati",
				endpoint: "/training",
				fieldType: "select",
				fieldValue: [],
				maxValues: -1,
				minValues: 0,
				property: "title",
				required: false
			},
			{
				fieldName: "published",
				fieldLabel: "Pubblicazione",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			},
		],
		submitEndpoint: "/selection"
	},
	collection: {
		contentType: "collection",
		labelContentType: "Collection",
		fields: [
			{
				fieldName: "title",
				fieldLabel: "titolo",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "body",
				fieldLabel: "descrizione",
				fieldType: "html",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "cover",
				fieldLabel: "immagine",
				endpoint: "/image",
				fieldType: "select_media",
				fieldValue: [],
				maxValues: 1,
				minValues: 0,
				property: "title",
				required: false,
			},
			{
				fieldName: "count_content",
				fieldLabel: "numero cotenuti",
				fieldType: "number",
				fieldValue: 0,
				required: false,
			},

			{
				fieldName: "creation_date",
				fieldLabel: "data creazione",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "updated_date",
				fieldLabel: "ultimo aggiornamento",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "survey",
				endpoint: "/survey",
				fieldLabel: "survey",
				fieldType: "select",
				fieldValue: "",
				maxValues: 1,
				minValues: 0,
				property: "name",
				required: false,

			},
			{
				fieldName: "roles",
				fieldLabel: "Ruoli associati",
				endpoint: "/role",
				fieldType: "select",
				fieldValue: [],
				maxValues: -1,
				minValues: 0,
				required: false,
			},
			{
				fieldName: "levels",
				fieldLabel: "Livelli associati",
				endpoint: "/level",
				fieldType: "select",
				fieldValue: [],
				maxValues: -1,
				minValues: 0,
				property: "name",
				required: false,
			},
			{
				fieldName: "new_hired",
				fieldLabel: "New Hired",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			},
			{
				fieldName: "esigenze_formative",
				fieldLabel: "esigenze formative",
				endpoint: "/esigenze_formative",
				fieldType: "select",
				fieldValue: [],
				maxValues: 1,
				minValues: 1,
				property: "name",
				required: true
			},
			{
				fieldName: "product_auto_add",
				fieldLabel: "assegnazione automatica",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			},
			{
				fieldName: "content",
				endpoint: "/learning_object",
				fieldLabel: "LO",
				fieldType: "select",
				fieldValue: [],
				maxValues: -1,
				minValues: 1,
				property: "title",
				required: false
			},
			{
				fieldName: "published",
				fieldLabel: "Pubblicazione",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			},
		],
		submitEndpoint: "/collection"
	},
	training: {
		contentType: "training",
		labelContentType: "Contenuti Obbligatori",
		fields: [
			{
				fieldName: "title",
				fieldLabel: "titolo",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "body",
				fieldLabel: "descrizione",
				fieldType: "html",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "cover",
				fieldLabel: "immagine",
				endpoint: "/image",
				fieldType: "select_media",
				fieldValue: [],
				maxValues: 1,
				minValues: 0,
				property: "title",
				required: false,
			},
			{
				fieldName: "brightcove_trailer",
				fieldLabel: "Video brightcove",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "duration",
				fieldLabel: "Durata (in secondi)",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "esigenze_formative",
				endpoint: "/esigenze_formative",
				fieldLabel: "esigenze formative",
				fieldType: "select",
				fieldValue: [],
				maxValues: 1,
				minValues: 1,
				property: "name",
				required: true,
			},
			{
				fieldName: "content_url",
				fieldLabel: "link contenuto",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "type",
				fieldLabel: "Type",
				fieldType: "simple_select",
				fieldValue: "",
				optionsStatic: [{
					id: 'video',
					name: 'Video',
				},
					{
						id: 'web',
						name: 'Web',
					}],
				maxValues: 1,
				minValues: 0,
				required: true,
			},
			{
				fieldName: "final_test",
				fieldLabel: "test finale",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			},
			{
				fieldName: "creation_date",
				fieldLabel: "data creazione",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "updated_date",
				fieldLabel: "ultimo aggiornamento",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "published",
				fieldLabel: "Pubblicazione",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			}
		],
		submitEndpoint: "/training"
	},
	live_event: {
		contentType: "live event",
		labelContentType: "Eventi Live",
		fields: [
			{
				fieldName: "title",
				fieldLabel: "titolo",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "body",
				fieldLabel: "descrizione",
				fieldType: "html",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "cover",
				fieldLabel: "immagine",
				endpoint: "/image",
				fieldType: "select_media",
				fieldValue: [],
				maxValues: 1,
				minValues: 0,
				property: "title",
				required: false,
			},
			{
				fieldName: "mode",
				fieldLabel: "modalità",
				fieldType: "simple_select",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "documents",
				fieldLabel: "Documenti",
				fieldType: "select_file",
				endpoint: "/file",
				fieldValue: [],
				maxValues: -1,
				minValues: 0,
				property: "title",
				required: false,
			},
			{
				fieldName: "duration",
				fieldLabel: "Durata (in secondi)",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "esigenze_formative",
				endpoint: "/esigenze_formative",
				fieldLabel: "esigenze formative",
				fieldType: "select",
				fieldValue: [],
				maxValues: 1,
				minValues: 1,
				property: "name",
				required: false,
			},
			{
				fieldName: "sessions",
				fieldLabel: "Sessioni",
				fieldType: "select",
				fieldValue: [],
				maxValues: -1,
				minValues: 0,
				endpoint: "/session",
				property: "name",
				required: true,
			},
			{
				fieldName: "creation_date",
				fieldLabel: "data creazione",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "updated_date",
				fieldLabel: "ultima modifica",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "published",
				fieldLabel: "Pubblicazione",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			}

		],
		submitEndpoint: "/live_event"
	},
	learning_object: {
		contentType: "learning object",
		labelContentType: "Learning Object",
		fields: [
			{
				fieldName: "title",
				fieldLabel: "titolo",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "body",
				fieldLabel: "descrizione",
				fieldType: "html",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "cover",
				fieldLabel: "immagine",
				endpoint: "/image",
				fieldType: "select_media",
				fieldValue: [],
				maxValues: 1,
				minValues: 0,
				property: "title",
				required: false,
			},
			{
				fieldName: "brightcove_trailer",
				fieldLabel: "Video brightcove",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "duration",
				fieldLabel: "Durata (in secondi)",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "esigenze_formative",
				endpoint: "/esigenze_formative",
				fieldLabel: "esigenze formative",
				fieldType: "select",
				fieldValue: [],
				maxValues: 1,
				minValues: 1,
				property: "name",
				required: true,
			},
			{
				fieldName: "content_url",
				fieldLabel: "link contenuto",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "type",
				fieldLabel: "Type",
				fieldType: "simple_select",
				fieldValue: "",
				optionsStatic: [{
					id: 'video',
					name: 'Video',
				},
					{
						id: 'web',
						name: 'Web',
					}],
				maxValues: 1,
				minValues: 0,
				required: true,
			},
			{
				fieldName: "homework",
				fieldLabel: "Homework",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			},
			{
				fieldName: "creation_date",
				fieldLabel: "data creazione",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "updated_date",
				fieldLabel: "ultimo aggiornamento",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "published",
				fieldLabel: "Pubblicazione",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			}
		],
		submitEndpoint: "/learning_object"
	},
	news: {
		contentType: "news",
		labelContentType: "News",
		fields: [
			{
				fieldName: "title",
				fieldLabel: "titolo",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "body",
				fieldLabel: "descrizione",
				fieldType: "html",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "cover",
				fieldLabel: "immagine",
				endpoint: "/image",
				fieldType: "select_media",
				fieldValue: [],
				maxValues: 1,
				minValues: 0,
				property: "title",
				required: false,
			},
			{
				fieldName: "reading_duration",
				fieldLabel: "Durata (in secondi) lettura",
				fieldType: "text",
				fieldValue: 0,
				required: true,
			},
			{
				fieldLabel: "esigenze formative",
				endpoint: "/esigenze_formative",
				fieldName: "esigenze_formative",
				fieldType: "select",
				fieldValue: [],
				maxValues: 1,
				minValues: 0,
				property: "name",
				required: false
			},
			{
				fieldName: "promoted",
				fieldLabel: "promoted",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			},
			{
				fieldName: "creation_date",
				fieldLabel: "data creazione",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "updated_date",
				fieldLabel: "ultimo aggiornamento",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "published",
				fieldLabel: "Pubblicazione",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			}
		],
		submitEndpoint: "/training"
	},
	session: {
		contentType: "session",
		labelContentType: "Sessione",
		fields: [
			{
				fieldName: "title",
				fieldLabel: "titolo",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "teachers",
				fieldLabel: "Docenti",
				fieldType: "select",
				fieldValue: [],
				maxValues: -1,
				minValues: 0,
				endpoint: "/teacher",
				property: "name",
				required: false,
			},
			{
				fieldName: "start_date",
				fieldLabel: "data inizio",
				fieldType: "dateInfo",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "end_date",
				fieldLabel: "data fine",
				fieldType: "dateInfo",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "max_spots",
				fieldLabel: "numero massimo posti",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "link",
				fieldLabel: "Link",
				fieldType: "multiField",
				fieldValue: [],
				required: false,
			},
			{
				fieldName: "location",
				fieldLabel: "Location",
				fieldType: "multiField",
				fieldValue: [],
				required: false,
			},
			{
				fieldName: "creation_date",
				fieldLabel: "data creazione",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "updated_date",
				fieldLabel: "ultima modifica",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "published",
				fieldLabel: "Pubblicazione",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			}
		],
		submitEndpoint: "/session"
	},
	survey: {
		contentType: "survey",
		labelContentType: "Survey",
		fields: [
			{
				fieldName: "title",
				fieldLabel: "titolo",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "questions",
				fieldLabel: "Domande",
				fieldType: "select",
				fieldValue: [],
				maxValues: -1,
				minValues: 0,
				endpoint: "/questions",
				property: "name",
				required: true,
			},
			{
				fieldName: "creation_date",
				fieldLabel: "data creazione",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "updated_date",
				fieldLabel: "ultima modifica",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "published",
				fieldLabel: "Pubblicazione",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			}
		],
		submitEndpoint: "/survey"
	},
	questions: {
		contentType: "question",
		labelContentType: "Question",
		fields: [
			{
				fieldName: "title",
				fieldLabel: "titolo",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "question",
				fieldLabel: "question",
				fieldType: "html",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "question_type",
				fieldLabel: "tipologia question",
				fieldType: "simple_select",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "answers",
				fieldLabel: "Risposte",
				fieldType: "multiField",
				fieldValue: [
					""],
				required: false,
			},
			{
				fieldName: "has_comment",
				fieldLabel: "commenti",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			},
			{
				fieldName: "comment_placeholder",
				fieldLabel: "descrizione commento",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "max_value",
				fieldLabel: "punteggio massimo",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "max_value_label",
				fieldLabel: "placeholder punteggio massimo",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},
			/*{
				fieldName: "min_value",
				fieldLabel: "punteggio minimo",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},*/
			{
				fieldName: "min_value_label",
				fieldLabel: "placeholder punteggio minimo",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "creation_date",
				fieldLabel: "data creazione",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "updated_date",
				fieldLabel: "ultima modifica",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "published",
				fieldLabel: "Pubblicazione",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			}
		],
		submitEndpoint: '/questions'
	},
	teacher: {
		contentType: "teacher",
		labelContentType: "Anagrafica Docente",
		fields: [
			{
				fieldName: "surname",
				fieldLabel: "Cognome",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "name",
				fieldLabel: "Nome",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "email",
				fieldLabel: "E-mail",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "description",
				fieldLabel: "descrizione",
				fieldType: "html",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "cover",
				fieldLabel: "immagine",
				endpoint: "/image",
				fieldType: "select_media",
				fieldValue: [],
				maxValues: 1,
				minValues: 0,
				property: "title",
				required: false,
			},
			{
				fieldName: "creation_date",
				fieldLabel: "data creazione",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "updated_date",
				fieldLabel: "ultima modifica",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "published",
				fieldLabel: "Pubblicazione",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			}],
		submitEndpoint: 'teacher'
	},
	calendar: {
		contentType: "calendar",
		labelContentType: "Edizione",
		fields: [
			{
				fieldName: "title",
				fieldLabel: "titolo",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "body",
				fieldLabel: "descrizione",
				fieldType: "html",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "sessions",
				fieldLabel: "Sessioni",
				fieldType: "association",
				fieldValue: [],
				endpointSession: "/session",
				endpointLive_events: "/live_event",
				required: true,
			},
			{
				fieldName: "creation_date",
				fieldLabel: "data creazione",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "updated_date",
				fieldLabel: "ultimo aggiornamento",
				fieldType: "date",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "published",
				fieldLabel: "Pubblicazione",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			}
		],
		submitEndpoint: '/calendar'

	},
	link: {
		fields: [
			{
				fieldName: "link_uri",
				fieldLabel: "url link",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "link_title",
				fieldLabel: "nome link",
				fieldType: "text",
				fieldValue: "",
				required: true,
			}
		]
	},
	location: {
		fields: [
			{
				fieldName: "city",
				fieldLabel: "città",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "address",
				fieldLabel: "indirizzo",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "latitudine",
				fieldLabel: "latitudine",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "longitudine",
				fieldLabel: "longitudine",
				fieldType: "text",
				fieldValue: "",
				required: true,
			}
		]
	},
	answer: {
		field: [
			{
				fieldType: 'text'
			}
		]
	},
	product_manager: {
		contentType: 'Pacchetto',
		labelContentType: "Percorso",
		fields: [
			{
				fieldName: "title",
				fieldLabel: "titolo",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "body",
				fieldLabel: "descrizione",
				fieldType: "html",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "cover",
				fieldLabel: "immagine",
				endpoint: "/image",
				fieldType: "select_media",
				fieldValue: [],
				maxValues: 1,
				minValues: 0,
				property: "title",
				required: false,
			},
			{
				fieldName: "learning_contents",
				fieldLabel: "Contenuti formativi",
				endpoint: "/learning_contents",
				fieldType: "select",
				fieldValue: [],
				maxValues: -1,
				minValues: 0,
				property: "name",
				required: false,
			},
			{
				fieldName: "calendar_choice",
				fieldLabel: "scelta edizione",
				endpoint: "/calendar",
				fieldType: "simple_select",
				required: false,
			},
			{
				fieldName: "calendar",
				fieldLabel: "lista calendari",
				endpoint: "/calendar",
				fieldType: "select",
				fieldValue: [],
				maxValues: -1,
				minValues: 0,
				property: "name",
				required: false,
			},
			{
				fieldName: "roles",
				fieldLabel: "Ruoli associati",
				endpoint: "/role",
				fieldType: "select",
				fieldValue: [],
				maxValues: 1,
				minValues: 0,
				property: "name",
				required: false,
			},
			{
				fieldName: "levels",
				fieldLabel: "Livelli associati",
				endpoint: "/level",
				fieldType: "select",
				fieldValue: [],
				maxValues: 1,
				minValues: 0,
				property: "name",
				required: false,
			},
			{
				fieldName: "new_hired",
				fieldLabel: "New Hired",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			},
			{
				fieldName: "is_premium",
				fieldLabel: "Premium",
				fieldType: "boolean",
				fieldValue: false,
				required: false,
			},
			{
				fieldName: "completion_days",
				fieldLabel: "Giorni di completamento",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "expiration_date",
				fieldLabel: "data scadenza",
				fieldType: "dateInfo",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "published",
				fieldLabel: "Pubblicazione",
				fieldType: "boolean",
				fieldValue: false,
				required: true,
			},

		],
		submitEndpoint: 'product_manager'
		
	}
	
};
