import useEnvironment from "api/useEnvironment";
import { FETCHER_MODE } from "@/utils/FetcherModes";

function useFetcher() {
	const domainUrl =  "https://prelios.sit.digitedacademy.net"; // TODO: CHANGE WITH window.location.origin;
  const { environment } = useEnvironment();

  /** fetcherFactory
   * @param  {...any} args[0] is supposed to be one of the values of FETCHER_MODE
   * if that's not the case the factory sets it to a valid default value and
   * forwards the arguments to the actual fetcher function
   * @returns a fetcher function
   */
  function fetcherFactory(...args) {
    if (!Object.values(FETCHER_MODE).includes(args[0])) {
      return fetcherFactory(FETCHER_MODE.DRUPAL)(...args);
    } else {
      switch(args[0]) {
        case FETCHER_MODE.DRUPAL:
          return (environment === "mock") ? mockFetcher : proxyFetcher;
        case FETCHER_MODE.MIDDLEWARE:
          return (environment === "mock") ? mockFetcher : middleWareFetcher;
        default:
          return () => {}
      };
    };
  };

  return fetcherFactory;

  async function mockFetcher(url, options) {
    const newOptions = { ...options }

    if (options.body) {
      newOptions.body = JSON.stringify(options.body)
    }

    const res = await fetch(url, newOptions);

    if (!res.ok) throw new Error('Failed to fetch')
    const json = await res.json()

    // TODO: remove check when all mocks are aligned
	  return Object.keys(json).includes("data") && Object.keys(json).includes("pager")
    ? {data: json.data, pager: json.pager}
    : json;
  };

  async function proxyFetcher(url, options) {
    const payload = {
      body: JSON.stringify({
        method: options.method,
        endpoint: url,
        domainUrl: domainUrl,
      }),
      headers: {
        "content-type": "application/json;charset=UTF-8",
        "x-api-key": "g1KSFg7H1A9QzGhSASviP8EmXqAsqqGt9iERQo2p",
      },
      method: "POST",
    }

    if (options.body) {
      payload.body.body = options.body
    }

    if (options.authToken) {
      payload.headers.authorization = options.authToken
    }

    let baseUrl;
    switch (environment) {
        case "dev":
                baseUrl = "https://s4b2apidev.ispfdevelop.com";
                break;
        case "prod":
                baseUrl = "https://api.skills4business.it";
                break;
        case "sit":
                baseUrl = "https://s4b2api.ispfdevelop.com";
                break;
        case "mock":
                baseUrl = '';
                break;
        default:
            throw new Error(`unknown environment ${environment}`);
    };


    const res = await fetch(
      baseUrl + "/proxy/api/v1/public_proxy",
      payload
    );

    if (!res.ok) throw new Error('Network response was not OK')
    const json = await res.json()

    // TODO: remove check when all mocks are aligned
    return Object.keys(json).includes("data") && Object.keys(json).includes("pager")
	    ? {data:json.data, pager: json.pager}
      : json;
  };

  async function middleWareFetcher(url, options) {
    const payload = {
      headers: {
        "content-type": "application/json;charset=UTF-8",
        "x-api-key": "g1KSFg7H1A9QzGhSASviP8EmXqAsqqGt9iERQo2p",
        "Domain": 212,
      },
      method: options.method,
    }

    if (options.body) {
      payload.body = JSON.stringify(options.body)
    }

    if (options.authorization) {
      payload.headers.authorization = options.authorization
    }

    const userParam = options.userId
      ? { key: "userId", value: options.userId }
      : undefined
    const authenticatedUrl = appendQueryParam(url, userParam)

    const res = await fetch(
      authenticatedUrl,
      payload
    );

    if (!res.ok) throw new Error('Network response was not OK')
    const json = await res.json()

    // TODO: remove check when all mocks are aligned
    return Object.keys(json).includes("data") && Object.keys(json).includes("pager")
	    ? {data: json.data, pager: json.pager}
      : json;
  }
};

function appendQueryParam(url, options) {
  if (!options?.key || !options?.value) return url;
  const { key, value } = options;
  const hasQUeryString = url.indexOf("?") > -1
  return hasQUeryString
    ? `${url}&${key}=${value}`
    : `${url}?${key}=${value}`
}

export default useFetcher;