import useEnvironment from "api/useEnvironment";

function useUploadMiddleware() {
	const { environment } = useEnvironment();

	async function upload({file, endpoint, userId, newsfeedId}) {

	const body = {
		file: file,
		userId: userId,
		newsfeedId: newsfeedId
	}

	  let baseUrl;
	  switch (environment) {
		  case "dev":
				  baseUrl = "https://s4b2apidev.ispfdevelop.com";
				  break;
		  case "prod":
				  baseUrl = "https://api.skills4business.it";
				  break;
		  case "sit":
				  baseUrl = "https://s4b2api.ispfdevelop.com";
				  break;
		  case "mock":
				  baseUrl = '';
				  break;
		  default:
			  throw new Error(`unknown environment ${environment}`);
	  };

        const res = await fetch(
            baseUrl + endpoint,
            {
                body:
                    JSON.stringify(body),
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "x-api-key": "g1KSFg7H1A9QzGhSASviP8EmXqAsqqGt9iERQo2p",
                    "Domain": 212,
					"Authorization": localStorage.getItem('Authorization')
                },
                method: 'POST',
                reportProgress: true,
                responseType: 'json',

            }
        )

        if (!res.ok){
            let json = await res.json();
            json = {...json, status: 'error'}
            return json
        }
        else {
            let json = await res.json();
            json = json.message.match(/\d/) ? json.message.replace(/\D/g, '') : json

            return json
        }
	};

	  const readFile = ({file, endpoint}) => {
		  return new Promise((resolve, reject) => {
			  const reader = new FileReader();
			  reader.addEventListener("load", () => {
				  const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");

                  console.log(file)

				  upload({
					  file: file,
					  endpoint
				  })
					  ?.then(res => res)
					  ?.then(data => {
						  resolve({data, file: file.name})
					  })
					  ?.catch(error => {
						  reject(error)
					  });

			  });
			  reader.addEventListener("error", (error) => {
				  reject(error);
			  })
			  reader.readAsDataURL(file);
		  })
	  };

	  const readPayload = (payload) => {
        return Array.isArray(payload)
            ? payload.map(payload => readFile(payload))
            : [payload].map(payload => readFile(payload))
	  };

	  return readPayload;
  }

  export default useUploadMiddleware;