import React, {useState, useEffect} from "react";
import {useNavigate, Link} from "react-router-dom";
import useFileUpload from 'api/useFileUpload';
import useFetcher from 'api/fetcher';
import useRoutes from 'api/useRoutes';
import { FETCHER_MODE } from 'utils/FetcherModes';
import Spinner from "components/Spinner";
import {styled} from '@mui/material/styles';
import { useQuery } from "@tanstack/react-query";

import {
	Box,
	Button,
	FormControl,
	Grid,
	Input,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableHead,
	TableRow,
	Typography,} from "@mui/material";
import Icon from "components/Icon";

import {FormikProvider, useFormik} from "formik";
import MuiAlert from '@mui/material/Alert';
import {RECORDS_FIELDS} from "api/recordsFields";
import useTheme from "api/siteTheme";
import useRequests from "api/useRequests";
import useGetTheme from "api/siteTheme";
import * as yup from "yup";
import NoResults from "components/NoResults";


export default function CreatePost() {
	const [open, setOpen] = useState(false);
	const [responseText, setResponseText] = useState('');
	const [responseType, setResponseType] = useState('');
	const [responseRequestPost, setResponseRequestPost] = useState();
	const [file, setFile] = useState();
	const [resUploading, setResUploading] = useState('');
	const [uploadedFilesList, setUploadedFilesList] = useState([]);

	function isObject(value) {
		return (
			typeof value === 'object' &&
			value !== null &&
			!Array.isArray(value)
		);
	}
	
	const theme = useTheme();
	const contentForm = RECORDS_FIELDS;
	
	const navigate = useNavigate();
	
	useFileUpload();
	
	useEffect(() => {
		if (responseRequestPost) {
			setResponseType(responseRequestPost[0]?.status || 'error');
			setResponseText(responseRequestPost?.[0]?.message || responseRequestPost?.[0]?.error  || responseRequestPost?.[0]?.errorManagement ||
			'success');
		}
	}, [responseRequestPost])
	
	const requestToSend = useRequests();

	
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};
	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});
	
	
	const handleFileChange = (e) => {
		if (e?.target?.files) {
			setFile(e.target.files[0]);
			setResUploading('')
		}
	};

	const StyledTableHeadCell = styled(TableCell)(({theme}) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,

			'&.reload': {
				cursor: "pointer"
			}
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
		}
	}));


	async function getBase64(file) {

		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.addEventListener("load", () => {

				const base64String =
				{
					name: file.name,
					size: file.size,
					type: file.type,
					id: new Date(),
					file: reader.result.replace("data:", "").replace(/^.+,/, "")
				};
				resolve(base64String)
			});
			reader.addEventListener("error", (error) => {
				reject(error)
			})
		})
	};

	function createFileData(file) {
		if (!file) return null;
		const fileForm = (typeof File === 'function')
			? new File([file.file || file], file.name || file)
			: new Blob([file.file || file]); // IE11

		return fileForm;
	}

	async function handleUploadClick() {
    		if (!file ) {
    			setResUploading('nessun file inserito')
    			setFile(null)
    		}
    		else if(file?.type !== 'text/csv') {

    			setResUploading('supportato solo formato csv')
    			setFile(null)
    		}
    		else{
    			let formData = new FormData();
    			formData.append('file', file);
    			if (!formData) {
    				setResUploading('select a type file')

    			} else {

    				let response;
    				const url = `/social/api/v1/learner_upload?upload_type=CSV_ANAGRAFICA`;
    				const token = localStorage.getItem("Authorization");
    				const fileUpload = requestToSend([formData], url, 'POST', false, token, true)
    				response = await Promise.all(fileUpload)?.then(data => {
    						setOpen(true);
    						return data
    					}
    				)?.catch(error => {
    					setOpen(true);
    					return error
    				});
    				setResponseRequestPost(response)
    			}
    		}
    	};

    const userId = localStorage.getItem("UserId");
    const authorization = localStorage.getItem("Authorization");
	console.log("upload records route auth", authorization, userId);
	const fetcher = useFetcher();
	const { getUploadRecordsEndpoint } = useRoutes();
	const uploadRecordsEndPoint = getUploadRecordsEndpoint(5);

	const { data: files, isFetching: loadingContents, refetch } = useQuery({
	  queryKey: ["uploadRecords"],
	  queryFn: () => fetcher(FETCHER_MODE.MIDDLEWARE)(
		uploadRecordsEndPoint,
		{
				  authorization,
				  method: "GET"
		}
	  )
	});

	function handleReload() {
		refetch();
	}

	useEffect(() => {
        if(files){
            setUploadedFilesList(files?.files)
        }
	}, [files])
	
	return (
		<Grid
			container
			justifyContent="space-between"
			flexWrap={'nowrap'}
			height={'100%'}
		>
			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
			          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
				<Alert onClose={handleClose} severity={`${responseType}`} sx={{width: '100%'}}>
					{responseText}
				</Alert>
			</Snackbar>
			<Grid
				item
				xs={12}
				container
				justifyContent="center"
				height={'100%'}
			>
				<Grid
					item xs={12}
					align="left"
					alignItems="center"
					component={Typography}
					justifyContent="center"
					variant="h3"
					gutterBottom
					textTransform="capitalize"
					padding={'20px 20px'}
					color={theme.palette.primary.main}
				>
					{contentForm.contentType}
				</Grid>

				<Grid
                					item xs={12}
                					align="center"
                					alignItems="center"
                					justifyContent="center"
                					variant="h3"
                					gutterBottom

                					padding={'20px 20px'}
					                color={theme.palette.primary.main}>

                                    <Grid
                                        item xs={12}
                                        align="left"
                                        alignItems="center"
                                        component={Typography}
                                        justifyContent="center"
                                        variant="h4"
                                        gutterBottom
                                        textTransform="capitalize"
                                        padding={'10px 0px'}
                                        color={theme.palette.primary.main}
                                    >
                                        Ultimi file caricati
                                    </Grid>
                					<div className="table-container">
                						{loadingContents ? (
                							<Spinner/> ) : (
                							Array.isArray(uploadedFilesList) && uploadedFilesList.length > 0 ? (
                								<Table>
                									<TableHead sx={{background: `${theme.palette.primary.main}`}}>
                										<TableRow sx={{'& td, & th': {border: 0,}}}>
                											<StyledTableHeadCell sx={{width: '30px'}}>
                												Nome file
                											</StyledTableHeadCell>
                											<StyledTableHeadCell sx={{width: '100px'}}>
                												Data
                											</StyledTableHeadCell>
                											<StyledTableHeadCell>
                												Status
                											</StyledTableHeadCell>
                											<StyledTableHeadCell>
                												Link
                											</StyledTableHeadCell>
                											<StyledTableHeadCell className={'reload'} onClick={()=> handleReload()}>
                												Ricarica
                											</StyledTableHeadCell>
                										</TableRow>
                									</TableHead>
                									<TableBody sx={{textTransform: "capitalize"}}>
                										{uploadedFilesList.map((file) => (
                											<TableRow
                												key={`content--${file.fileName}`}
                												sx={{
                													boxShadow: 1,
                													"& .MuiTableCell-body": {paddingY: 3},
                													'& td, & th': {border: 0},
                												}}>
                												<TableCell>
                													<Typography>
                														{file.fileName}
                													</Typography>
                												</TableCell>
                												<TableCell>
                													<Typography>
                														{`${file.timestamp.slice(8,10)}-${file.timestamp.slice(5,7)}-${file.timestamp.slice(0,4)}`}
                													</Typography>
                												</TableCell>
                												<TableCell>
                													<Typography>
                														{file.status}
                													</Typography>
                												</TableCell>
                												<TableCell>
                													{file.status === 'ERROR' && <Icon
                                                                     onClick={() => {
                                                                     console.log(file);
                                                                        const xhr = new XMLHttpRequest();
                                                                        xhr.open('GET', file.payload);
                                                                        xhr.setRequestHeader('Authorization', localStorage.getItem('Authorization'));
                                                                        xhr.onload = function() {
                                                                        console.log(xhr);
                                                                          if (xhr) {
                                                                              const url = window.URL.createObjectURL(new Blob([xhr.response]));
                                                                              const link = document.createElement('a');
                                                                              link.href = url;
                                                                              link.setAttribute('download', file.fileName);
                                                                              document.body.appendChild(link);
                                                                              link.click();
                                                                        }
                                                                        }
                                                                      xhr.send();
                                                                    }}
                                                                     sx={{color: `${theme.palette.primary.main}`}} type={'DownloadIcon'} />}


                												</TableCell>
                											</TableRow>
                										))}
                									</TableBody>
                								</Table>
                							) : (
                								<NoResults/>
                							)
                						)}
                					</div>
                				</Grid>


				<Grid
					item xs={12}
				>

					<Grid
						item
						xs={12}
						container
						rowSpacing={4}
						display={'block'}
						padding={'16px 32px'} height={'100%'}>

                        <Grid
                            item xs={12}
                            align="left"
                            alignItems="center"
                            component={Typography}
                            justifyContent="center"
                            variant="h4"
                            gutterBottom
                            textTransform="capitalize"
                            padding={'10px 0px'}
                            color={theme.palette.primary.main}
                        >
                            Carica nuovo file
                        </Grid>
				<Link to={'/template_caricamento_anagrafica.csv'} target="_blank" download
				      style={{color: `${theme.palette.primary.main}`, height: 'fit-content'}}>Scarica file esempio</Link>
						<Box>
							<Typography
								marginX={3}
								sx={{
									color: `${theme.palette.primary.main}`,
									margin: '0 0 4px 0',
									fontSize: '0.75rem'
								}}
							>
								{resUploading !== '' ?
									<span style={{color: '#f44336'}}> {resUploading}</span> : null}
								{file ?
									<span>File selezionato : {isObject(file) ? file?.name : file}</span> : null}
							</Typography>
							<Input
								sx={{
									alignItems: "center",
									border: 1,
									borderColor: "#d9d9d9",
									borderRadius: "4px",
									display: "flex",
									paddingY: 2,
									justifyContent: "space-between",
									width: "100%",
									padding: '9px',
									color: '#666666',
									'input': {
										'&::-webkit-file-upload-button': {
											background: 'rgb(51, 167, 178)',
											border: 'none',
											borderRadius: '4px',
											boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;',
											marginRight: '10px',
											cursor: 'pointer',
											transition: 'all .1s linear',
											color: 'white',
											'&:hover': {
												backgroundColor: 'rgb(35, 116, 124)',
												boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;'
											}
										}
									}
								}}
								id={'file'}
								name={'file'}
								label={'file anagrafica'}
								disableUnderline
								type="file"
								accept="file/*"
								onChange={(event) => {
									event.preventDefault();
									handleFileChange(event);
								}}
							/>
						</Box>
					</Grid>

					<Grid
						item
						xs={12}
						container
						fullwidth="true"
						justifyContent="center"
						position={'sticky'}
						bottom={0}
						right={0}
						left={0}
						style={{background: 'white', zIndex: '3', boxShadow: '0 0 15px rgba(0, 0, 0, 0.15)'}}
					>
						<Grid item padding={'16px'} width={'fit-content'}>
							<Button
								fullwidth="true"
								variant="outlined"
								onClick={() => navigate('/')}
							>
								annulla
							</Button>
						</Grid>
						<Grid item width={'fit-content'} padding={'16px'}>
							<Button
								fullwidth="true"
								type="submit"
								variant="contained"
								sx={{color: 'white'}}
								onClick={() => {
									handleUploadClick()
								}}
							>
								salva
							</Button>
						</Grid>
					</Grid>
				</Grid>


			</Grid>
		
		</Grid>
	);


};


