import { useEffect, useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// import _debounce from "lodash.debounce";
import useFetcher from 'api/fetcher';
import useRoutes from 'api/useRoutes';
import InputSelectStatic from "components/InputSelectStatic";
import NoResults from "components/NoResults";
import Spinner from "components/Spinner";
import { useContext } from "routes/Assign";
import { FETCHER_MODE } from "utils/FetcherModes";
import useStateDebounce from "utils/useStateDebounce";
import { useTheme } from '@mui/material/styles';

function AssignStep3() {
  const authorization = localStorage.getItem("Authorization");
  const userId = localStorage.getItem("UserId");
  const MAXIMUM_SESSION_SEATS = 15;

  const fetcher = useFetcher();
  const theme = useTheme();
  // const debouncedFetcher = _debounce(fetcher, 200)
  const { getUsersEndpoint } = useRoutes();
  const [managerId] = useContext("managerId")
  const [maximumSessionSeats] = useContext("maximumSessionSeats")
  const [pageSize] = useContext("pageSize")
  const [selectedPackage] = useContext("selectedPackage")
  const [selectedUsers, setSelectedUsers] = useContext("selectedUsers")
  
  const [newHired, setNewHired] = useState(false)
  const [usersFilters, setUsersFilters] = useState({
    level: null,
    email: null,
    role: null
  });
  const debouncedUsersFilters = useStateDebounce(usersFilters, 200)
  const filters = useMemo(
    () => {
      const mappedFilters =
        Object.entries(debouncedUsersFilters)
          .filter(([_, val]) => val) // filters out all falsy values
          .map(([key, val]) => ({
            field: mapFilterKey(key),
            value: val
          }));

      if (mappedFilters.length < 1) {
        return { field: "ALL", value: null };
      } else {
        return mappedFilters[0];
      }
    },
    [debouncedUsersFilters]
  );

  const {
    data: users,
    hasNextPage,
    isLoading: loadingUsers,
    fetchNextPage
  } = useInfiniteQuery({
    getNextPageParam: (lastPage) => lastPage?.nextPage,
    keepPreviousData: true,
    queryKey: ["usersList", pageSize, managerId, selectedPackage.zuoraProductId, filters?.field, filters?.value, newHired],
    queryFn: ({ pageParam=null }) => {
      const usersEndpoint = getUsersEndpoint({
        filter_by: filters?.field,
        filter_value: filters?.value,
        items: pageSize,
        manager_id: managerId,
        new_hired: newHired,
        next_page: pageParam,
        product_id: selectedPackage.zuoraProductId
      });
      // return debouncedFetcher(FETCHER_MODE.MIDDLEWARE)(
      return fetcher(FETCHER_MODE.MIDDLEWARE)(
        usersEndpoint,
        {
          authorization,
          method: "GET",
          userId
        }
      )
    },
  });
  
  useEffect(() => {
    if (!users?.userInfoList) return;
    let newSelectedUsers = {};
    users.userInfoList.forEach((user) => {
      newSelectedUsers[user.email] = selectedUsers[user.email] ?? false;
    });
    setSelectedUsers(newSelectedUsers)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  const selectedEmails =
    Object.entries(selectedUsers)
      .filter(([_, val]) => val)
      .map(([key, _]) => key)

  return (loadingUsers) ? (
    <Spinner />
  ) : (
    <>

      <Stack
        boxSizing="border-box"
        divider={<Divider />}
        gap={1}
        width="100%"
      >

        <Stack
        gap={1}
        sx={{color: theme.palette.primary.main}}>
          Filtra per:
        </Stack>
        <Stack gap={2}>
          <Stack
            direction="row"
            flexWrap="wrap"
            gap={1}
            // marginTop="-30px"
          >
            {
              selectedEmails.map((email, idx) => (
                <Chip
                  key={`email-chip-${idx}`}
                  label={email}
                />
              ))
            }
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            padding={2}
          >
            <TextField
              disabled={usersFilters.level || usersFilters.role}
              label="email"
              InputProps={{
                endAdornment: usersFilters.email ? (
                  <CancelOutlinedIcon
                    color="warning"
                    onClick={() => setUsersFilters({
                      level: null,
                      email: null,
                      role: null
                    })}
                    sx={{ cursor: "default" }}
                  />
                ) : null
              }}
              // margin="normal" // uncomment if label is removed
              onChange={(changeEvent) => {
                setUsersFilters({
                  level: null,
                  email: changeEvent.target.value,
                  role: null
                })
              }}
              placeholder="cerca"
              sx={{ minWidth: "35%" }}
              value={usersFilters.email || ""}
              variant="standard"
            />
            <InputSelectStatic
              disabled={usersFilters.email || usersFilters.role}
              endAdornment={
                usersFilters.level ? (
                  <CancelOutlinedIcon
                    color="warning"
                    onClick={() => {
                      setUsersFilters({
                        level: null,
                        email: null,
                        role: null
                      })
                    }}
                    sx={{ cursor: "default" }}
                  />
                ) : null
              }
              label="livello"
              optionsPath={"/level"}
              setValue={(changeEvent) => {
                setUsersFilters({
                  level: changeEvent.target.value,
                  email: null,
                  role: null
                })
              }}
              sx={{
                ".MuiSelect-icon": {
                  display: usersFilters.level ? "none" : undefined
                },
                minWidth: "25%"
              }}
              value={usersFilters.level || ""}
              variant="standard"
            />
            <InputSelectStatic
              disabled={usersFilters.level || usersFilters.email}
              endAdornment={
                usersFilters.role ? (
                  <CancelOutlinedIcon
                    color="warning"
                    onClick={() => {
                      setUsersFilters({
                        level: null,
                        email: null,
                        role: null
                      })
                    }}
                    sx={{ cursor: "default" }}
                  />
                ) : null
              }
              label="ruolo"
              optionsPath={"/role"}
              setValue={(changeEvent) => {
                setUsersFilters({
                  level: null,
                  email: null,
                  role: changeEvent.target.value,
                })
              }}
              sx={{
                ".MuiSelect-icon": {
                  display: usersFilters.role ? "none" : undefined
                },
                minWidth: "25%"
              }}
              value={usersFilters.role || ""}
              variant="standard"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={newHired}
                  onChange={(changeEvent) =>{
                    setNewHired(changeEvent.target.checked)
                  }}
                />
              }
              label="New Hired"
            />
          </Stack>
        </Stack>

            <Grid
            alignItems="center"
            boxSizing="border-box"
            container
            direction="row"
            paddingX={2}
            paddingY={4}
            width="100%"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white
              }}
          >
            <Grid item xs={4} textAlign="start">
              Email
            </Grid>
            <Grid item xs={4} overflow="hidden">
              <Typography
                noWrap
              >
                Nominativo
              </Typography>
            </Grid>
            <Grid item xs={2} overflow="hidden">
              <Typography noWrap>
                Ruolo
              </Typography>
            </Grid>
            <Grid item xs={2} overflow="hidden">
              <Typography noWrap>
                New Hired
              </Typography>
            </Grid>
          </Grid>
        {
          (
            (Array.isArray(users?.pages) && users?.pages.length > 0) && 
            (Array.isArray(users?.pages[0].userInfoList) && users?.pages[0].userInfoList.length > 0)
          ) ? (
            users?.pages.map((page) => (
              page.userInfoList?.map((user) => (
                <Grid
                  key={`user-row--${user.userId}`}
                  alignItems="center"
                  boxSizing="border-box"
                  container
                  direction="row"
                  paddingX={2}
                  paddingY={4}
                  width="100%"
                >
                  <Grid item xs={4} textAlign="start">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedUsers[user.email] ?? false}
                          disabled={
                            !Boolean(selectedUsers[user.email]) &&
                            (maximumSessionSeats >= 0) &&// default is -1
                            (Math.min(maximumSessionSeats, MAXIMUM_SESSION_SEATS) <= selectedEmails.length)
                          }
                          onChange={(changeEvent) => {
                            const newSelectedUsers = Object.assign({}, selectedUsers)
                            newSelectedUsers[user.email] = changeEvent.target.checked
                            setSelectedUsers(newSelectedUsers)
                          }}
                        />
                      }
                      label={user.email}
                    />
                  </Grid>
                  <Grid item xs={4} overflow="hidden">
                    <Typography
                      noWrap
                    >
                      {user.givenName} {user.familyName}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} overflow="hidden">
                    <Typography noWrap>
                      {user.userJob}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} overflow="hidden">
                    <Typography noWrap>
                      {user.newHired === true ? 'New Hired' : null }
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ))
          ) : (
            <NoResults paddingY="1rem"/>
          )
        }
      </Stack>
      <Stack
        alignItems="center"
        boxSizing="border-box"
        width="100%"
      >
        <Divider
          sx={{ marginBottom: 2}}
          width="40%"
        />
        <Button
          disabled={!hasNextPage}
          onClick={() => { hasNextPage && fetchNextPage() }}
        >
         carica altro
        </Button>
      </Stack>
    </>
  );

  function mapFilterKey(val) {
    switch(val) {
      case "level":
        return "LEVEL";
      case "email":
        return "EMAIL";
      case "role":
        return "ROLE";
      default:
        return "ALL";
    };
  };
}

export default AssignStep3;
