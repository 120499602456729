import React, {useState, useEffect} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { useQuery } from "@tanstack/react-query";
import useFetcher from 'api/fetcher';
import useRoutes from 'api/useRoutes';
import Icon from "components/Icon";
import NoResults from "components/NoResults";
import { FETCHER_MODE } from 'utils/FetcherModes';
import useTheme from "api/siteTheme";


function RenderDate({open, setOpen, params}) {
  const [openError, setOpenError] = useState(false);
	const [responseText, setResponseText] = useState('');
	const [responseType, setResponseType] = useState('');
	const [responseRequestPost, setResponseRequestPost] = useState();
	const { getEnrollmentHREndpoint } = useRoutes();

  const handleCloseAlert = (event) => {
    setOpen(false);
    setResponseType('');
  }

  const handleOpenAlert = (event) => {
    setOpen(true);
  }

  const handleCloseAlertError = (event) => {
    setOpenError(false);
    setResponseType('');
  }

    const Alert = React.forwardRef(function Alert(params, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...params} />;
  });
  return (
    <strong>
    {!!responseType && !!responseText && <Snackbar
      className="success-tt"
      open={open}
      autoHideDuration={3000}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      onClose={handleCloseAlert}>
        <Alert severity={`${responseType}`} sx={{width: '100%'}}>
          {responseText}
        </Alert>
      </Snackbar>}
      {!!responseType && !!responseText && <Snackbar //error api call
        className="error-tt"
        open={openError}
        autoHideDuration={3000}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={handleCloseAlertError}>
          <Alert severity={`${responseType}`} sx={{width: '100%'}}>
            {responseText}
          </Alert>
      </Snackbar>}
      <Button
        variant="outlined"
        size="small"
        color="success"
        onClick={(event) => {
          let payload = {"requestItems": [{
            "userEmail": params.row.email,
            "itemId": params.row.productId,
            "recommendationId": params.row.recommendationId,
            "sessionId": params.row.sessionId,
            "accepted": true
          }]};
          const xhr = new XMLHttpRequest();
          xhr.open('POST', 'https://s4b2api.ispfdevelop.com/asset/api/v1/corporate/enrollment/fromHR');
            xhr.setRequestHeader('Domain', 212);
            xhr.setRequestHeader('Authorization', localStorage.getItem('Authorization'));
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.onload = function() {
              if (xhr.status === 200) {
                setResponseType('success');
                setResponseText(responseRequestPost ? responseRequestPost?.[0]?.message.toString() : 'Richiesta accettata');
                handleOpenAlert();
              } else {
                setResponseType('error');
                setResponseText( responseRequestPost ? responseRequestPost.toString() : `Ops! qualcosa é andato storto`);
                setOpenError(true);
              }
            }
          xhr.send(JSON.stringify(payload));
        }}>
        <Icon
          type="CheckBoxIcon"
          color="success"
          fontSize="medium"
        />
      </Button>
      <Button
        variant="outlined"
        size="small"
        color="error"
        style={{ marginLeft: 16 }}
        onClick={(event) => {
          let payload = {"requestItems": [{
            "userEmail": params.row.email,
            "itemId": params.row.productId,
            "recommendationId": params.row.recommendationId,
            "sessionId": params.row.sessionId,
            "accepted": false
          }]};
          const xhr = new XMLHttpRequest();
                xhr.open('POST', 'https://s4b2api.ispfdevelop.com/asset/api/v1/corporate/enrollment/fromHR');
            xhr.setRequestHeader('Domain', 212);
            xhr.setRequestHeader('Authorization', localStorage.getItem('Authorization'));
              xhr.setRequestHeader("Content-Type", "application/json");
            xhr.onload = function() {
            if (xhr.status === 200) {
              setResponseType('error');
              setResponseText(responseRequestPost ? responseRequestPost?.[0]?.message.toString() : 'Richiesta rifiutata');
              handleOpenAlert();
            } else {
              setResponseType('error');
              setResponseText( responseRequestPost ? responseRequestPost.toString() : 'Ops! qualcosa é andato storto');
              setOpenError(true);
            }
          };
          xhr.send(JSON.stringify(payload));
        }}
      >
        <Icon
          type="DeleteIcon"
          color="error"
          fontSize="medium"
        />
      </Button>
    </strong>
  );
}

export default function DataTable() {
  const [open, setOpen] = useState(null);
  const authorization = localStorage.getItem("Authorization");
  const userId = localStorage.getItem("UserId");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 7,
    page: 0,
  });
  const fetcher = useFetcher();
  const { getRequestsEndpoint } = useRoutes();
  const theme = useTheme();
  const requestsEndpoint = getRequestsEndpoint(paginationModel.page, paginationModel.size, localStorage.getItem('UserId'));

  const { data: rows, isFetching: loadingContents, refetch } = useQuery({
    queryKey: ["requests", paginationModel.page, paginationModel.size],
    queryFn: () => fetcher(FETCHER_MODE.MIDDLEWARE)(
      requestsEndpoint,
      {
        authorization,
        method: "GET",
        userId
      }
    ),
  });

	useEffect(() => {
    if (!open) {
      console.log('loading table')
		  refetch();
    }
	}, [open, refetch ]);

  const columns  = [
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'firstName', headerName: 'Nome', width: 130 },
    { field: 'lastName', headerName: 'Cognome', width: 130 },
    { field: 'type', headerName: 'Tipologia', width: 130 },
    { field: 'status', headerName: 'Stato', width: 130 },
    {
      field: 'title',
      headerName: 'Titolo',
      width: 200,
      valueGetter: (params) =>
        `${params.row?.title || '-'}`,
    },
    {
      headerName: 'Approvazione',
      field: 'approval',
      width: 180,
      sortable: false,
      renderCell: (params) => RenderDate({open, setOpen, params}),
    },
  ];

  return (
    <Grid container sx={{
      flexDirection: "column",
      flexWrap: "nowrap",
      height: "85vh",
      padding: 4,
    }}>
    <Grid
            item xs={12}
            align="left"
            component={Typography}
            variant="h3"
            gutterBottom
            textTransform="capitalize"
            padding={'0px'}
            color={theme.palette.primary.main}
        >
            {'Le tue richieste'}
        </Grid>
      <Grid item container
        alignItems="center"
        justifyContent="space-between"
        marginBottom={5}
      >
        <div style={{
          height: '90vh',
          width: '100%'
        }}>
          {loadingContents ? (
            <Box sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              height: "100%"
            }}>
              <CircularProgress size={80}/>
            </Box>
          ) : Array.isArray(rows?.recommendations) && rows.recommendations?.length > 0 ? (
            <DataGrid
              rows={rows?.recommendations}
              getRowId={(row) => row.recommendationId}
              columns={columns}
              pageSize={paginationModel.page}
              rowsPerPageOptions={[paginationModel.pageSize]}
              onPaginationModelChange={setPaginationModel}
            />
          ) : (
            <NoResults/>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
