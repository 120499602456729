import React, { useEffect, useState } from "react";
import {TextField} from "@mui/material";
import * as yup from "yup";
import useTheme from "api/siteTheme";
import {resetGroupFields} from "components/InputField";

function InputText({
	contentFields,
	controlForm,
	controlGroup,
	controlLabel,
	controlName,
	isCreating,
	required
}) 
{
	const theme = useTheme();
	const isDisabled = controlName === 'max_spots' ? !isCreating : false;
	const [fieldVisible, setFieldVisible] = useState(false);
	const [isRequired, setIsRequired] = useState(false);


	useEffect(() => {
		if (controlName === 'comment_placeholder' ||
			controlName === 'max_value' || controlName === 'max_value_label' ||
			controlName === 'min_value' || controlName === 'min_value_label'
		) {
			if (controlForm.values.question_type !== 'question_evaluation') {
				setFieldVisible(false);
			} else {
				setFieldVisible(true);
				setIsRequired(true);
			}
		} else {
			setFieldVisible(true);
		}
	}, [controlForm])

	return (
		<>
			{fieldVisible && <TextField
				sx={{
					'& .MuiOutlinedInput-root': {
						'&:hover fieldset': {
							borderColor: `${theme.palette.primary.main}`,
						},
					},
					
				}}
				disabled={isDisabled}
				id={controlName}
				name={controlName}
				label={`${controlLabel}${required || isRequired ? '*' : ''}`}
				fullWidth
				value={controlForm.values[controlName]}
				onChange={handleChange}
				error={controlForm.touched[controlName]
					&& Boolean(controlForm.errors[controlName])}
				helperText={controlForm.touched[controlName]
					&& controlForm.errors[controlName]}
			/>}
		</>
	);

	function handleChange(value) {
		resetGroupFields(
			contentFields,
			controlForm,
			controlGroup,
			controlName
		);
		controlForm.handleChange(value);
	};
};

export const inputTextValidator = ({
	controlLabel,
	controlName,
	controlIsRequired
}) => {
	const validator = {
		[controlName]: yup.string(`inserisci un valore per  ${controlLabel}`).nullable()
	};
	if (controlIsRequired) {
		validator[controlName] =
			validator[controlName].required(`${controlLabel} è obbligatorio`);
	};
	return validator;
};

export default InputText;
