import useEnvironment from "@/api/useEnvironment";

function useRequests() {
	const domainUrl = "https://prelios.sit.digitedacademy.net";  //  TO DO CHANGE WITH window.location.origin;
	const {environment} = useEnvironment();

    let baseUrl;
    switch (environment) {
        case "dev":
                baseUrl = "https://s4b2apidev.ispfdevelop.com";
                break;
        case "prod":
                baseUrl = "https://api.skills4business.it";
                break;
        case "sit":
                baseUrl = "https://s4b2api.ispfdevelop.com";
                break;
        case "mock":
                baseUrl = '';
                break;
        default:
            throw new Error(`unknown environment ${environment}`);
    };

	async function requestResponse(valuesToSend, url, method, noDrupal, authorization, uploadCsv) {

		if(uploadCsv) {
			const res = await fetch(
				baseUrl + url,
				{
					body: valuesToSend,
					headers: {
						"x-api-key": "g1KSFg7H1A9QzGhSASviP8EmXqAsqqGt9iERQo2p",
						"authorization": authorization,
						"domain": 212,
					},
					method: method
				}
			)
			if (!res.ok) {
				let json = await res.json();
				json = {...json, status: 'error'}

				return json

			} else {
				let json = await res.json();
				json = {...json, status: 'success'}
				return json
			}
		}
		else if (noDrupal) {
			const res = await fetch(
				baseUrl + url,
				{
					body: JSON.stringify(valuesToSend),
					headers: {
						"content-type": "application/json",
						"x-api-key": "g1KSFg7H1A9QzGhSASviP8EmXqAsqqGt9iERQo2p",
						"authorization": authorization,
						"domain": 212,
					},
					method: method
				}
			)
			if (!res.ok) {
				let json = await res.json();
				json = {...json, status: 'error'}

				return json

			} else {
				let json = await res.json();
				json = {...json, status: 'success'}
				return json
			}

		} else {
			const res = await fetch(
			baseUrl + "/proxy/api/v1/public_proxy",
				{
					body:
						JSON.stringify({
							body: JSON.stringify(valuesToSend),
							endpoint: url,
							method: method,
							domainUrl: domainUrl
						}),
					headers: {
						"content-type": "application/json",
						"x-api-key": "g1KSFg7H1A9QzGhSASviP8EmXqAsqqGt9iERQo2p",
					},
					method: "POST"
				}
			)
			if (!res.ok) {
				let json = await res?.json();
				json = {...json, status: 'error'}
				return json
			} else {
				let json = await res?.json();
				json = {...json, status: 'success'}
				return json
			}
		}
	};

	const getRequest = (valuesToSend, url, method, noDrupal = false, authorization = null,  uploadCsv=false) => {
		return valuesToSend.map(value => {
			return new Promise((resolve, reject) => {
				requestResponse(value, url, method, noDrupal, authorization, uploadCsv)
					?.then(res => res)
					?.then(data => {
						resolve(data)
					})
					?.catch(error => {
						reject(error)
					})
			});
		})

	}

	return environment === "mock" ? getRequest : getRequest

}

export default useRequests;
