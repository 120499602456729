import React, {useState, useEffect} from "react";
import {Link, Outlet, useLocation} from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
	Box,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Grid,
} from "@mui/material";
import {styled} from '@mui/material/styles';
import {CONTENT_TYPES} from "api/config";
import useFetcher from "api/fetcher";
import useTheme from "api/siteTheme";
import useRoutes from "api/useRoutes";
import Icon from "components/Icon";
import {FETCHER_MODE} from "utils/FetcherModes";
import {ICONS_MAP} from "utils/IconsMap";

const WrappedLink = ({isActive, ...props}) => <Link {...props} />;

const StyledLink = styled(WrappedLink)(({isActive, theme}) => (
	{
		color: theme.palette.primary.main,
		textDecoration: "none",
		textTransform: "capitalize",
		transition: 'all .15s linear',
		borderBottom: ` 1px solid ${isActive ? `${theme.palette.primary.main}` : "transparent"
		}`,
		'&:hover': {
			borderColor: `${theme.palette.primary.main}`
		}
	}));

function Root() {
	const authorization = localStorage.getItem("Authorization");
  const userId = localStorage.getItem("UserId");
	const {getUserInfoEndpoint} = useRoutes();

	const {pathname} = useLocation();
	const fetcher = useFetcher();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = useState(true);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		function resizeWindow() {
			setWindowWidth(window.innerWidth);
			setMobileOpen(window.innerWidth > 900)
		};
		resizeWindow();
		window.addEventListener('resize', resizeWindow);
	}, []);
	const drawerWidth = windowWidth < 675 ? '80%' : 240;

	const userInfoEndpoint = getUserInfoEndpoint();
	useQuery({
		queryKey: ["userInfo", authorization, userId],
		queryFn: () => fetcher(FETCHER_MODE.MIDDLEWARE)(
			userInfoEndpoint,
			{
				authorization,
				method: "GET",
				userId
			}
		)
	});

	// const [tokenAuth, setTokenAuth] = useState(null);
	useEffect(() => {
		var PERMITTED_DOMAIN = "https://prelios.sit.digitedacademy.net";
			window.addEventListener('message', function(event) {
				if (event.origin === PERMITTED_DOMAIN) {
					if (event.data) {
						const data = JSON.parse(event.data)
						localStorage.setItem("Authorization", data.Authorization);
						localStorage.setItem("UserId", data.UserId);
						// setTokenAuth(data.Authorization);
					} else {
						localStorage.removeItem("Authorization");
						localStorage.removeItem("UserId");
						// setTokenAuth(null);
					}
				}
			});
	}, []);

	return (
		<Box display="flex" flex="1 0 100%">
			<Drawer
				anchor="left"
				variant={windowWidth > 900 ? 'persistent' : 'temporary'}
				open={mobileOpen}
				onClose={() => setMobileOpen((prev) => !prev)}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					flexShrink: 0,
					width: drawerWidth,
					display: 'block',
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box', width: drawerWidth,
						zIndex:'2'
					}
				}}
			>
				<List>
					{Array.isArray(CONTENT_TYPES) && CONTENT_TYPES.map((contentType) => {
						const content = contentType.id.replace(/\s/, '-')
						const pathnames = pathname.replace('/', '');

						return (
							<ContentItem
								disabled={false}
								key={content}
								contentType={contentType.id}
								contentTypeTitle={contentType.title}
								isActive={pathnames ? pathnames === content : false}
							/>
						)
					})}
				</List>
			</Drawer>
			<Grid
				container
				columnSpacing={{xs: 1}}
				maxWidth={`calc(100vw - ${drawerWidth}px)`}
				minHeight="100vh"
				sx={{[theme.breakpoints.down('md')]: {
						maxWidth:'100%'
					}}}

			>
				<Grid
					sx={{
						width: '100%',
						display: 'none',
						alignItems: 'center',
						gap: '16px',
						position: 'sticky',
						top: '0',
						background: 'white',
						zIndex: '3',
						boxShadow: '  rgba(0, 0, 0, 0.15) 0px 0px 15px',
						color: `${theme.palette.primary.main}`,
						padding: '16px',
						[theme.breakpoints.down('md')]: {
							display: 'flex'
						}
					}}
					onClick={() => setMobileOpen((prev) => !prev)}
				>
					<Icon
						type={'MenuIcon'}
						sx={{
							background: `${theme.palette.primary.main}`,
							borderRadius: '5px',
							color: 'white',
						}}
					      fontSize={'large'}/>
					<h3>Open Menu</h3>
				</Grid>
				<Grid item xs={12}>
					<Outlet/>
				</Grid>
			</Grid>
		</Box>
	)
}

function MenuItem({name, path, icon, hasNew = false, disabled}) {
	const {pathname} = useLocation();
	const theme = useTheme();
	const content = path.replace(/\s/, '-')
	const pathnames = pathname.match(/(?!\/)[-0-9A-Za-z]+$/)

	return (
		<ListItem
			disablePadding
			sx={{paddingX: 3, paddingY: 1, opacity: `${disabled ? '0.5' : '1'}`, cursor: `${disabled ? 'not-allowed' : 'pointer'}`}}
			className={disabled ? 'disabled' : ''}
		>
			<ListItemIcon sx={{color: theme.palette.primary.main}}>
				<Icon type={icon}/>
			</ListItemIcon>
			<StyledLink
				sx={{color: theme.palette.primary.main, cursor: `${disabled ? 'not-allowed' : 'pointer'}`}}
				to={!disabled ? path : ''}
				isActive={pathnames ? pathnames[0] === content : false}
			>
				{/* replaces whitespaces with hyphens */}
				<ListItemText primary={name}/>
			</StyledLink>
			{hasNew ?
				<Icon
					color="primary"
					fontSize="large"
					type={'FiberNewIcon'}/>
				: null
			}
		</ListItem>
	)
}


function ContentItem({contentType, contentTypeTitle, isActive, disabled}) {
	const theme = useTheme();
	return (
		<ListItem
			key={contentType}
			disablePadding
			sx={{paddingX: 3, paddingY: 1, cursor: disabled ? 'not-allowed' : 'pointer', opacity:disabled ? '0.5' : 1}}
		>
			<ListItemIcon sx={{color: theme.palette.primary.main}}>
				<Icon type={ICONS_MAP[contentType]}/>
			</ListItemIcon>
			<StyledLink
				to={contentType.replace(/\s/, '-')}
				isActive={isActive}
				sx={{cursor: disabled ? 'not-allowed' : 'pointer', opacity:disabled ? '0.5' : 1}}
			>
				{/* replaces whitespaces with hyphens */}
				<ListItemText primary={contentTypeTitle}/>
			</StyledLink>
		</ListItem>
	)
}

export default Root;
