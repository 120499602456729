export const CONTENT_TYPES = [
	{
			api: "news",
			id: "news",
			path: "news",
			title: "News"
	},
	{
			api: "learning_object",
			id: "learning_object",
			path: "learning_object",
			title: "Learning Object"
	},
	{
			api: "collection",
			id: "collection",
			path: "collection",
			title: "Collection"
	},
	{
			api: "training",
			id: "training",
			path: "training",
			title: "Contenuti Obbligatori"
	},
	{
			api: "selection",
			id: "selection",
			path: "selection",
			title: "Selection"
	},
	{
			api: "questions",
			id: "questions",
			path: "questions",
			title: "Question"
	},
	{
			api: "survey",
			id: "survey",
			path: "survey",
			title: "Survey"
	},
	{
			api: "teacher",
			id: "teacher",
			path: "teacher",
			title: "Anagrafiche Docenti"
	},
	{
			api: "session",
			id: "session",
			path: "session",
			title: "Sessioni"
	},
	{
			api: "live_event",
			id: "live_event",
			path: "live_event",
			title: "Eventi Live"
	},
	{
			api: "calendar",
			id: "calendar",
			path: "calendar",
			title: "Edizioni"
	},
	{
			api: "product_manager",
			id: "product_manager",
			path: "product_manager",
			title: "Percorsi"
	},
	{
		api: "media",
		id: "media",
		path: "media",
		title: "Immagini"
	},
	{
		api: "file",
		id: "file",
		path: "file",
		title: "Documenti"
	}
];
