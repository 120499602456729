import React, {useState} from "react";
import {FormHelperText, TextField} from "@mui/material";
import {Grid, Typography} from "@mui/material";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDateTimePicker} from '@mui/x-date-pickers/MobileDateTimePicker';
import * as yup from "yup";
import dayjs from 'dayjs';
import {resetGroupFields} from "components/InputField";

function DatePickerInput({
	                         contentFields,
	                         controlForm,
	                         controlGroup,
	                         controlLabel,
	                         controlName,
	                         required
	
                         }) {
	const [valuePicker, setValuePicker] = useState(controlForm.values[controlName]);

	return (
		<Grid sx={{
			'.MuiTypography-root ': {
				display: 'inline-block',
				margin: '0 auto',
				width: '100%',
			},
				paddingBottom: '16px',
		}}>
			{
				Boolean(controlForm.errors[controlName]) ? (
					<FormHelperText
						sx={{
							color: `rgb(211, 47, 47)`,
							paddingBottom: '10px'
						}}>
							{controlForm.errors[controlName]}
					</FormHelperText>
				) : null
			}
			<Grid
				item xs={12}
				align="center"
				component={Typography}
				variant="p"
				gutterBottom
				textTransform="capitalize"
				paddingBottom={'8px'}
				color={'rgb(51, 167, 178)'}
			>
				{controlLabel}
			</Grid>
			{valuePicker ?
				<Grid
					item xs={12}
					align="center"
					component={Typography}
					variant="p"
					gutterBottom
					textTransform="capitalize"
					paddingBottom={'8px'}
					color={'rgb(51, 167, 178)'}
				
				>
					{new Date(valuePicker).toString()}
				</Grid>
				: null}
			<LocalizationProvider adapterLocale={'en'} dateAdapter={AdapterDayjs}>
				<MobileDateTimePicker
					slotProps={{
						textField: {
							error: Boolean(controlForm.errors[controlName]),
						},
					}}
					id={controlName}
					name={controlName}
					label={`${controlLabel}${required ? '*' : ''}`}
					value={dayjs(valuePicker ? valuePicker : controlForm.values[controlName])}
					onChange={(e) => {
						setValuePicker(new Date(e).toISOString());
						handleChange((new Date(e).getTime() / 1000).toString())
					}}
					helperText={Boolean(controlForm.errors[controlName])}
					renderInput={(params) => <TextField {...params} error={Boolean(controlForm.errors[controlName])} />}
					sx={{width: '100%'}}
				/>
			</LocalizationProvider>
		</Grid>
	);
	
	
	function handleChange(value) {
		resetGroupFields(
			contentFields,
			controlForm,
			controlGroup,
			controlName
		);
		controlForm.setFieldValue(
			controlName,
			value,
			true,
		);
	};
};

export const inputDatePickerValidator = ({
	                                         controlLabel,
	                                         controlName,
	                                         controlIsRequired
                                         }) => {
	const validator = {
		[controlName]: yup
			.string(`enter a value for ${controlLabel}`)
	};
	if (controlIsRequired) {
		validator[controlName] =
			validator[controlName]
				.required(`${controlLabel} is required`);
	}
	;
	return validator;
};

export default DatePickerInput;