import React, {useEffect} from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Grid
} from "@mui/material";
import {styled} from '@mui/material/styles';
import useTheme from "api/siteTheme";
import useFetcher from 'api/fetcher';
import useRoutes from 'api/useRoutes';
import { FETCHER_MODE } from 'utils/FetcherModes';
import { useQuery } from "@tanstack/react-query";
import Icon from "components/Icon";
import Spinner from "../Spinner";
import NoResults from "../NoResults";

	const StyledTableHeadCell = styled(TableCell)(({theme}) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,

			'&.reload': {
				cursor: "pointer"
			}
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
		}
	}));

function ReportTable({type}) {
const theme = useTheme();

    const userId = localStorage.getItem("UserId");
    const authorization = localStorage.getItem("Authorization");
	const fetcher = useFetcher();
	const { getReportsEndpoint } = useRoutes();
	const reportsEndPoint = getReportsEndpoint(type, userId);
  const [reportList, setReportList] = React.useState([]);

	const { data: reports, isFetching: loadingContents, refetch } = useQuery({
	  queryKey: ["reports", type],
	  queryFn: () => fetcher(FETCHER_MODE.MIDDLEWARE)(
		reportsEndPoint,
		{
				  authorization,
				  method: "GET"
		}
	  ),
	});

  useEffect(() => {
    refetch();
}, [type])

	useEffect(() => {
	    if(reports){
		    setReportList(reports.reportList)
	    }
	}, [reports])

  return (
    <>
      {!!loadingContents ? 
      <Spinner/> :
      !!reportList?.length ? <Table>
        <TableHead sx={{background: `${theme.palette.primary.main}`}}>
            <TableRow sx={{'& td, & th': {border: 0,}}}>
                <StyledTableHeadCell>
                    Tipologia
                </StyledTableHeadCell>
                <StyledTableHeadCell>
                    Range temporale
                </StyledTableHeadCell>
                <StyledTableHeadCell>
                    Download
                </StyledTableHeadCell>
            </TableRow>
        </TableHead>
        <TableBody sx={{textTransform: "capitalize"}}>
            {Array.isArray(reportList) && reportList.map((report) => (
                <TableRow
                    key={`report--${report.fileName}`}
                    sx={{
                        boxShadow: 1,
                        "& .MuiTableCell-body": {paddingY: 3},
                        '& td, & th': {border: 0},
                    }}>
                    <TableCell>
                        <Typography>
                            {report.reportType}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                        {report.rangeTime > 0 ?
                            <>Ultimi {report.rangeTime} giorni</>
                            : '-'}
                        </Typography>
                    </TableCell>
                    <TableCell>
                            <Icon
                            onClick={() => {
                                const xhr = new XMLHttpRequest();
                                xhr.open('GET', report.fileUri);
                                xhr.setRequestHeader('Authorization', localStorage.getItem('Authorization'));
                                xhr.onload = function() {
                                console.log(xhr);
                                  if (xhr) {
                                        const url = window.URL.createObjectURL(new Blob([xhr.response]));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', report.fileName);
                                        document.body.appendChild(link);
                                        link.click();
                                  }
                                }
                              xhr.send();
                            }}
                            sx={{color: `${theme.palette.primary.main}`}} type={'DownloadIcon'} />
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
      </Table> :
        <Grid
        item xs={12}
        align="center"
        alignItems="center"
        justifyContent="center"
        variant="h3"
        gutterBottom
        color={theme.palette.primary.main}>
          <NoResults/>
      </Grid>
      }
    </>
  );
};

export default ReportTable;