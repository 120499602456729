import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
	createTheme,
	ThemeProvider as MuiThemeProvider
	/* https://zenoo.github.io/mui-theme-creator/ */
} from '@mui/material';
import useFetcher from "./fetcher";
import useRoutes from "./useRoutes";

const inlineDefault = {
	logo: {
		default: 'public/logo_prelios_ottanio.png',
		rgb:'',
		white: '',
	},
	palette: {
		primary: {
			main: '#303030',
		},
		secondary: {
			main: '#ffffff',
		},
	},
	spacing: [0, '0.3rem', '0.5rem', '0.8rem', '1rem', '1.5rem', '2.5rem', '4rem'],
}

function useTheme() {
	const fetcher = useFetcher();
	const { getSiteThemeEndpoint } = useRoutes();
	const inlineTheme = useMemo(() => createTheme(inlineDefault), [])

	const siteThemeEndPoint = getSiteThemeEndpoint()
	const {data, isLoading} = useQuery({
		queryKey: ["theme"],
		queryFn: () => fetcher(siteThemeEndPoint, { method: "GET" }),
	})

	const theme = useMemo(() => themeAdapter(data), [isLoading])

	return theme ?? inlineTheme;
};

function themeAdapter(response) {
	const data = response?.data || response;
	if (data) {
		return createTheme({
			...inlineDefault,
			logo: {
				default: data[0].brand?.[0].logo,
				white: data[0].brand?.[0].logo_white,
				rgb: data[0].brand?.[0].logo_rgb,
			},
			palette: {
				primary: {
					main: data[0].brand?.[0].color,
				},
				secondary: {
					main: data[0].brand?.[0].color_2,
				},
			},
		})
	}
}

export function ThemeProvider({ children }) {
  const theme = useTheme()
  return (
    <MuiThemeProvider theme={theme}> {/* theming (MUI) */}
      { children }
    </MuiThemeProvider>
  )
}

export default useTheme;